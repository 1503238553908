import { TableCell } from "@mui/material";
import { filesize } from "filesize";
import { ColumnProps } from "./Column";
import React from "react";

export const FileSizeColumn = <Row, ColumnValue>({
  columnValue,
  columnIndex
}: ColumnProps<Row, ColumnValue>) => (
  <TableCell key={columnIndex}>{filesize(Number(columnValue))}</TableCell>
);
