import { callAsyncAction } from "presentation/core/action";
import { createUserAction } from "presentation/core/api/user/_actions";
import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import {
  DialogContentType,
  DialogType
} from "presentation/core/components/dialog/_types";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";
import NamedTitle from "presentation/core/components/namedTitle";
import { NotificationSeverity } from "presentation/core/components/notifications/_types";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { createDocumentDialog } from "../baseDocumentDialog/documentDialogFactory";
import { MetaDataTab } from "./MetaDataTab";
import { ModalSize } from "presentation/designSystem/Modal/Modal";
import React from "react";

export const createUserDialogContent: DialogContentType = createDocumentDialog({
  actions: () => [
    secondaryAction(
      t(translationPath(lang.dialog.form.confirm)),
      ({ dispatch, channels, dialogProps, onClose, buttonState }) => {
        const onSuccess = () => {
          onClose();
          dispatch(documentViewAction__Refresh(true));
          dialogProps.onSuccess?.();
        };

        const onError = () => {
          buttonState.setIsPending(false);
          dialogProps.onError?.();
        };

        buttonState.setIsPending(true);

        dispatch(
          callAsyncAction({
            action: createUserAction,
            onError,
            onErrorNotification: {
              message: t(
                translationPath(lang.dialog.notifications.createUserFailed)
              ),
              severity: NotificationSeverity.Error
            },
            onSuccess,
            onSuccessNotification: {
              message: t(
                translationPath(lang.dialog.notifications.createUserSucceeded)
              ),
              severity: NotificationSeverity.Success
            },
            payload: {
              body: channels.contentTab?.state?.formValues
            }
          })
        );
      }
    )
  ],
  content: MetaDataTab,
  title: (props) => (
    <NamedTitle text={t(translationPath(lang.general.createUser))} {...props} />
  ),
  type: DialogType.CreateUser,
  size: ModalSize.Default
});
