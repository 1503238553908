import { callAsyncAction } from "presentation/core/action";
import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import {
  DialogContentType,
  DialogType
} from "presentation/core/components/dialog/_types";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";
import NamedTitle from "presentation/core/components/namedTitle";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { ChangeValidityDateDialog } from "./ChangeValidityDateDialog";
import { filePlanChangeValidityDate } from "../../../../core/api/fileplan/_actions";
import { NotificationSeverity } from "../../../../core/components/notifications/_types";
import { FilePlansItem } from "../../../../core/components/documentView/_types";
import { ChangeValidToDateFormValues } from "./_types";
import moment from "moment";
import { ModalSize } from "presentation/designSystem/Modal/Modal";
import dayjs from "dayjs";

const REQUIRED_TIME_VALUE = 0;
const TIME_STAMP = {
  hour: REQUIRED_TIME_VALUE,
  minute: REQUIRED_TIME_VALUE,
  second: REQUIRED_TIME_VALUE,
  millisecond: REQUIRED_TIME_VALUE
};

export const changeValidityDateContent: DialogContentType = {
  actions: () => [
    secondaryAction(
      t(translationPath(lang.dialog.buttons.confirm)),
      ({ dispatch, channels, dialogProps, onClose, buttonState }) => {
        buttonState.setIsPending(true);

        const onSuccess = () => {
          dispatch(documentViewAction__Refresh(true));
          onClose();
        };

        const onError = () => {
          buttonState.setIsPending(false);
        };

        const id = (dialogProps.data as FilePlansItem)?.id;

        if (!id) {
          buttonState.setIsPending(false);
          return;
        }

        const formValues = channels.contentTab.state
          ?.formValues as ChangeValidToDateFormValues;

        dispatch(
          callAsyncAction({
            action: filePlanChangeValidityDate,
            onError,
            onErrorNotification: {
              message: t(
                translationPath(lang.dialog.notifications.actionFailed)
              ),
              severity: NotificationSeverity.Error
            },
            onSuccess,
            onSuccessNotification: {
              message: t(
                translationPath(lang.dialog.notifications.actionSucceeded)
              ),

              severity: NotificationSeverity.Success
            },
            payload: {
              body: {
                validFrom: dayjs(formValues?.validFrom).format(),
                validTo: dayjs(formValues?.validTo).isValid()
                  ? dayjs(formValues?.validTo).format()
                  : null
              },
              nodeId: id
            }
          })
        );
      }
    )
  ],
  content: ChangeValidityDateDialog,
  title: (props) => (
    <NamedTitle
      text={t(translationPath(lang.dialog.title.changeValidityDate))}
      {...props}
    />
  ),
  type: DialogType.ChangeValidityDate,
  size: ModalSize.Small
};
