import { DateTimePickerProps } from "@mui/lab/DateTimePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { useField, useFormikContext } from "formik";
import { DatepickerValueType } from "presentation/core/components/datepicker/_types";
import React, { useEffect, useState } from "react";
import { DateTimeFormats } from "../../../enums";
import { StyledDateTimePicker } from "./DateTimePicker.styles";

const format: string = DateTimeFormats.FullDateTime;

interface OwnProps {
  className?: string;
  component?: React.FC<DateTimePickerProps<any>>;
  name: string;
  label: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  disabled?: boolean;
  required?: boolean;
  style?: object;
  format?: string;
}

const DateTimePicker = ({
  component: Com = StyledDateTimePicker,
  disabled: disabledProp,
  ...props
}: OwnProps) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>("");
  const [disabled, setDisabled] = useState<boolean | undefined>(disabledProp);
  const { setFieldValue, errors, isSubmitting } = useFormikContext();
  const [field] = useField(props);
  const onChange = (value: DatepickerValueType) => {
    setFieldValue(field.name, value);
  };

  useEffect(() => {
    setDisabled(disabledProp || isSubmitting);
    setErrorMessage(errors[field.name as keyof typeof errors]);
  }, [errors, field, isSubmitting]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LocalizationProvider>
      <Com
        format={props.format || format}
        disabled={disabled}
        onChange={onChange}
        value={field.value}
        helperText={errorMessage}
        error={!!errorMessage}
        required={!!props.required}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default DateTimePicker;
