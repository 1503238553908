import DatePicker from "presentation/core/components/datepicker/DatePicker";
import { StyledKeyboardDatePickerHalf } from "presentation/core/components/datepicker/DatePicker.styles";
import clsx from "clsx";

import {
  responsiveFieldHalf,
  useStyles
} from "presentation/core/components/dialog/Dialog.styles";
import { FormState } from "presentation/core/components/reactiveFormik/_types";
import { Form } from "formik";
import React, { useState } from "react";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { ChangeValidToDateFormValues, changeValidToDateProxy } from "./_types";
import { isBefore } from "date-fns";

export const ChangeValidityDateDialogForm = React.memo(
  ({ values }: FormState<ChangeValidToDateFormValues>) => {
    const classes = useStyles();
    const [isEditing, setIsEditing] = useState(false);

    const datePickerDisabled =
      !isEditing &&
      !!(
        values?.validFrom && isBefore(new Date(values?.validFrom), new Date())
      );

    const onDateChange = () => {
      setIsEditing(true);
    };

    return (
      <Form className={clsx(classes.modalValidUntil, classes.form)}>
        <DatePicker
          component={StyledKeyboardDatePickerHalf}
          style={responsiveFieldHalf}
          data-test-id="file-plan-valid-from-date"
          disablePast={true}
          name={lastPathMember(changeValidToDateProxy.validFrom).path}
          label={t(translationPath(lang.dialog.form.validFrom))}
          required={true}
          disabled={datePickerDisabled}
          onDateChange={onDateChange}
        />
        <DatePicker
          component={StyledKeyboardDatePickerHalf}
          style={responsiveFieldHalf}
          data-test-id="file-plan-valid-to-date"
          name={lastPathMember(changeValidToDateProxy?.validTo).path}
          label={t(translationPath(lang.dialog.form.validTo))}
          disablePast={true}
        />
        <p>{t(translationPath(lang.dialog.form.note))}</p>
      </Form>
    );
  }
);
