import { DatePickerProps } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useField, useFormikContext } from "formik";
import { DatepickerValueType } from "presentation/core/components/datepicker/_types";
import { useEffect, useState } from "react";
import { DateTimeFormats } from "../../../enums";
import { StyledKeyboardDatePicker } from "./DatePicker.styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import EventIcon from "@mui/icons-material/Event";
import React from "react";

const format: string = DateTimeFormats.Date;

interface OwnProps {
  className?: string;
  component?: React.FC<DatePickerProps<any>>;
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  format?: string;
  label: string;
  name: string;
  onDateChange?: (value: DatepickerValueType) => void;
  required?: boolean;
  showCalendarIcon?: boolean;
  style?: object;
  shouldDisableDate?: (date: DatepickerValueType) => boolean;
  value?: any;
}

const DatePicker = ({
  //TODO find out, if this component is needed
  component: Component = StyledKeyboardDatePicker,
  disabled: disabledProp,
  ...props
}: OwnProps) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>("");
  const [disabled, setDisabled] = useState<boolean | undefined>(disabledProp);
  const { setFieldValue, errors, isSubmitting } = useFormikContext();
  const { onDateChange, showCalendarIcon = true, ...fieldProps } = props;
  const [field] = useField(fieldProps);
  const hideIcon = disabled || !showCalendarIcon;

  const handleOnChange = (value: DatepickerValueType) => {
    setFieldValue(field.name, value);
    if (onDateChange) {
      onDateChange(value);
    }
  };

  useEffect(() => {
    setDisabled(disabledProp || isSubmitting);
    setErrorMessage(errors[field.name as keyof typeof errors]);
  }, [errors, field, isSubmitting]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Component
        format={props.format || format}
        disabled={disabled}
        slotProps={{
          toolbar: { toolbarFormat: "ddd DD MMMM", hidden: false },
          textField: {
            margin: "none",
            helperText: errorMessage,
            error: !!errorMessage
          }
        }}
        onError={(error: any) => setErrorMessage(error ? error : undefined)}
        onChange={handleOnChange}
        value={field.value ? dayjs(field.value) : null}
        required={props.required}
        slots={{
          openPickerIcon: hideIcon ? () => null : EventIcon
        }}
        sx={{
          ...props.style
        }}
        {...fieldProps}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
